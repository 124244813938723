import { SET_ROUTE, CLEAR_ROUTES } from './actionTypes';

export function setRoute(path: string) {
    return {
        type: SET_ROUTE,
        path: path
    }
}

export function clearRoutes() {
    return {
        type: CLEAR_ROUTES
    }
}