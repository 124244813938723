import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { httpRequest } from '../../utils';
import { useApi } from '../../hooks/useApi';
import styles from './Promocode.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';

const Promocode = () => {
    const [open, setOpen] = useState(false);
    const { data, refresh } = useApi('GET', `${process.env.REACT_APP_API}/promocode`);
    
    const defaultValues = {
        promocode_name: '',
        promocode_expiration_date: '',
        promocode_discount_value: '',
        promocode_type: 'free-shipping',
        promocode_reusable: false
    }

    const promocodeTypesSelect = [
        {
            id: 0,
            value: 'free-shipping'
        },
        {
            id: 1,
            value: 'value-based'
        },
        {
            id: 2,
            value: 'percentage-based'
        }
    ];

    const [formState, setFormState] = useState({
        ...defaultValues
    })
    const handleCreate = () => {
        setOpen(true)
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        console.log(formState)
        httpRequest('POST', `${process.env.REACT_APP_API}/promocode`, {
            ...formState
        })
        .then(() => {
            refresh();
            setOpen(false);
            setFormState(defaultValues)
        })
    }

    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value    
    }))
    }

    const handleDatePick = (value) => {
        setFormState((prevState) => ({
            ...prevState,
            promocode_expiration_date: value
        }))
    }

    const handlePromocodeDelete = (id) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/promocode/${id}`)
        .then(() => {
            refresh()
        })
    }

    const handleCheckboxChange = (event) => {
        setFormState((prevState) => ({
            ...prevState,
            promocode_reusable: event.target.checked
        }))
    }

    return(
        <div>
            <Button onClick={handleCreate}>
                Створити промокод
            </Button>
            {/* Список промокодів із АПІ */}
            {data && data.map((it) => (
                <div className={styles.promocode_container}>
                        <span style={{color: 'red'}}>{it.promocode_name}</span>
                        <span style={{marginLeft: '15px'}}>{it.promocode_expiration_date}</span>
                        <span> {it.promocode_reusable ? 'Багаторазовий' : 'Одноразовий'}</span>
                        <span>Тип: {it.promocode_type}</span>
                    <IconButton>
                        <DeleteIcon onClick={() => handlePromocodeDelete(it.id)} />
                    </IconButton>
                </div>
            ))}
            {/* Функція видалення, редагування промокоду */}
            {/* Діалог із вікном створення промокодів */}
            <Dialog
                open={open}
                disableEnforceFocus
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title">
                <DialogTitle>Створити промокод</DialogTitle>
                <form onSubmit={handleFormSubmit}>
                    <DialogContent>
                    <TextField
                        autoFocus
                        variant="outlined"
                        required={true}
                        margin="dense"
                        id="promocode_name"
                        label="Промокод"
                        type="text"
                        name="promocode_name"
                        value={formState.promocode_name}
                        fullWidth
                        onChange={handleChange}
                    />
                    <TextField
                        autoFocus
                        variant="outlined"
                        required={true}
                        margin="dense"
                        id="promocode_discount_value"
                        label="Розмір знижки, %"
                        type="number"
                        name="promocode_discount_value"
                        value={formState.promocode_discount_value}
                        fullWidth
                        onChange={handleChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="promocode_type">Тип промокоду</InputLabel>
                        <Select
                            labelId='promocode_type_label'
                            id="promocode_type_select"
                            fullWidth
                            sx={{
                                padding: '4px',
                                marginBottom: '5px'
                            }}
                            name="promocode_type"
                            value={formState.promocode_type}
                            label="Тип промокоду"
                            onChange={handleChange}>
                            {promocodeTypesSelect.map((val) => (
                                <MenuItem key={val.id} value={val.value}>{val.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormGroup>
                        <FormControlLabel 
                            label="Багаторазове використання"
                            control={
                            <Checkbox 
                                checked={formState.promocode_reusable}
                                onChange={handleCheckboxChange}
                                inputProps={{ 'aria-label': 'controlled'}}/>} 
                            />
                    </FormGroup>
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                        value={formState.promocode_expiration_date}
                        name="promocode_expiration_date"
                        onChange={handleDatePick}
                        />
                    </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button color="warning" onClick={() => setOpen(false)}>
                            Скасувати
                        </Button>
                        <Button type="submit">
                            Створити
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default Promocode;