import React, { useState, useEffect } from 'react';
import ProductActions from './ProductsActions';
import ProductsTable from './ProductsTable/ProductsTable';
import { useApi } from '../../hooks/useApi';
import { httpRequest } from '../../utils';


const Products = () => {
    const [dataNotLoaded, setDataNotLoaded] = useState(true);
    const [prodData, setProdData] = useState([]);
    const { data, loading, error, refresh } = useApi('GET', `${process.env.REACT_APP_API}/products`, dataNotLoaded);
    
    const handleProductCopy = (id: number) => {
        httpRequest('POST', `${process.env.REACT_APP_API}/products/copy/${id}`)
            .then(res => {
            })
        refresh();
    }
    useEffect(() => {
        if (data && !error) {
            setDataNotLoaded(false);
            setProdData(data)
        }
    }, [data, error]);
    return(
        <div>   
            <ProductActions />
            <ProductsTable 
                data={prodData} 
                loading={loading}
                handleCopy={(id) => handleProductCopy(id)}
            />
         </div>
        
    );
}

export default Products;