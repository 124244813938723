import React from 'react';
import { useApi } from '../../hooks/useApi';
import {
    Accordion, AccordionDetails, AccordionSummary, Typography,
    Divider, IconButton, Chip
 } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Categories.scss';
import CreationModal from '../../components/CreationModal';
import Skeleton from '@mui/material/Skeleton';
import { ICategory } from '../../types/index';
import DeleteIcon from '@mui/icons-material/Delete';
import { httpRequest } from '../../utils';
import { NewCategoryFilter } from './NewCategoryFilter';

const Categories = () => {
    const { loading, data, refresh } = useApi<ICategory>('GET', `${process.env.REACT_APP_API}/categories`);

    const handleCatDelete = (id) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/categories/${id}`)
            .then(res => {
                refresh();
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleChipDelete = (e, id) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/category-filters/${id}`)
            .then(res => {
                refresh();
            })
    }
    return (
        <div>
            <div className="action-items_page">
                <CreationModal 
                    disable={loading} 
                    type="create"
                    dialogTitle="Створення категорії" 
                    dialogText="Заповніть усі поля для створення нової категоріїї продуктів"
                    categoriesData={data}
                    onRefresh={refresh}
                /> 
                
            </div>
            <div className="page-content__categories">
                {loading ? (
                    <>
                    <Skeleton variant="rectangular" height={20} />
                    <br />
                    <Skeleton variant="rectangular" height={20} />
                    <br />
                    <Skeleton variant="rectangular" height={20} />
                    <br /> 
                    <Skeleton variant="rectangular" height={20} />
                    <br />
                    <Skeleton variant="rectangular" height={20} />
                    </>
                ) : (
                <div className="page-content">
                    {data && data.map((cat: any) => (
                        <Accordion key={cat.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="categories-list"
                                id="categories-list">
                                    <Typography>
                                        {cat.name}
                                    </Typography>
                                    <Typography>
                                        --- {cat.id}
                                    </Typography>
                            </AccordionSummary>
                            <NewCategoryFilter categoryId={cat.id} />
                            <div className='categories-list__main'>
                                {cat.filters && cat.filters.length > 0 && cat.filters.map(filter => (
                                    <Chip sx={{marginRight: '4px'}} label={filter.filter_name}  onDelete={(e) => handleChipDelete(e, filter.id)}/>
                                ))}
                            </div>
                            <Divider />
                            <AccordionDetails>
                                <div className="categories-details__main">    
                                    <CreationModal
                                        disable={loading}
                                        type="edit"
                                        onRefresh={refresh}
                                        dialogTitle="Редагування категорії" 
                                        dialogText="Заповніть усі поля для створення нової категоріїї продуктів"
                                        categoriesData={data}
                                        categoryData={cat}
                                    />
                                    <IconButton onClick={() => handleCatDelete(cat.id)} size="large">
                                            <DeleteIcon />
                                    </IconButton>
                                </div>
                                <div className="categories-content__main">

                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
                )}
            </div>
        </div>
    );
};

export default Categories;