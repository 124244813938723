import { combineReducers, createStore} from 'redux';
import authReducer from '../reducers/authReducer';
import routeReducer from '../reducers/routesReducer';
import { StateSaver } from '../../services/StateSaver';

const stateSaver = new StateSaver();
const rootReducer = combineReducers({
    authReducer,
    routeReducer 
});

const store = createStore(rootReducer, stateSaver.loadState(), (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(() => {
    stateSaver.saveState(store.getState());
})

export default store;