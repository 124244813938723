import React from 'react';
import './LoginLayout.scss';

const LoginLayout = props => {
    const { children } = props;
    return(
        <div className="login-layout-main">
            <div className="bg-img"></div>
            <div className="login-layout-children">
                {children}
            </div>
        </div>
    );
};

export default LoginLayout;