import React, { useEffect, useState } from 'react';
import {
    Button, Select, TextField,
    MenuItem
} from '@mui/material';
import { httpRequest } from '../../utils';

export const NewCategoryFilter = (props) => {
    const [startFilterCreating, setStartFilterCreating] = useState(false);

    const [formState, setFormState] = useState({
        filter_name: '',
        category: props.categoryId
    });


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const name = event.target.name;

        setFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleNewFilterCreate = () => {
        if (startFilterCreating) {
            httpRequest('POST', `${process.env.REACT_APP_API}/category-filters`, formState)
        }
    }

    const handleSelect = (event: React.ChangeEvent<{ value: unknown}>) => {
        const value = event.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            type_value: value
        }))
    }

    const handleFilterCreationStart = () => {
        setStartFilterCreating(true);
    }

    return(
        <div style={{padding: '5px'}}>
            {!startFilterCreating ? <Button 
                variant="contained" 
                color="secondary"
                onClick={handleFilterCreationStart}
            >
                Додати новий фільтр
            </Button> : ( 
                <form style={{width: '400px'}}>
                    <TextField 
                        variant="outlined"
                        margin="dense"
                        id="filter-name"
                        label="Назва фільтру"
                        type="text"
                        name="filter_name"
                        value={formState.filter_name}
                        fullWidth
                        onChange={handleChange}
                    />
                    <Button onClick={handleNewFilterCreate} color="secondary" variant="contained" fullWidth>Створити</Button>
                </form>
            )}
        </div>
    );
}