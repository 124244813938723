import React from 'react';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import Routes from './Routes';
import { Provider } from 'react-redux';
import store from './redux/store/index';
import { CookiesProvider } from 'react-cookie';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/styles';

import { createTheme } from '@mui/material';

export const theme = createTheme({

})

const App: React.FC = () => {
  return (
      <ThemeProvider theme={theme}>
      <Auth0Provider
        domain={process.env.REACT_APP_DOMAIN as string}
        clientId={process.env.REACT_APP_CLIENT_ID as string}
        redirectUri={process.env.REACT_APP_REDIRECT_URI}
        audience={process.env.REACT_APP_AUDIENCE as string}
        scope="read:users"
        cacheLocation='localstorage'
        useRefreshTokens={true}>
        <CookiesProvider>
          <Provider store={store as any}>
                <Router>
                  <Routes />
                </Router>
          </Provider>
        </CookiesProvider>
      </Auth0Provider>
      </ThemeProvider>
  );
}

export default App;
