import React, { useState } from 'react';
import { 
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    TextField
} from '@mui/material';
import { httpRequest } from '../../../utils';

interface IPTagsCreate {
    open: boolean;
    onClose: () => void;
    productId: string;
}

const ProductTagsCreate:React.FC<IPTagsCreate> = (props) => {
    const { open, onClose, productId } = props;

    const [formState, setFormState] = useState({
        tag_name: '',
        tag_slug: '',
        tag_description: '',
        product: productId
    });

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        httpRequest('POST', `${process.env.REACT_APP_API}/product-tag`, {
            ...formState
        })
        .then(() => {
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    };

    return(
        <Dialog
            open={open}
            onClose={onClose}>
            <form onSubmit={handleFormSubmit}>
                <DialogTitle>Додайте новий Тег товару</DialogTitle>
                <DialogContent>
                    <TextField
                        id="tag_name"
                        name="tag_name"
                        variant="outlined"
                        label="Назва Тегу"
                        type="text"
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={formState.tag_name}
                    />
                    <TextField
                        id="tag_slug"
                        name="tag_slug"
                        variant="outlined"
                        label="Коротке посилання Тегу"
                        type="text"
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={formState.tag_slug}
                    />
                    <TextField
                        id="tag_description"
                        name="tag_description"
                        variant="outlined"
                        label="Опис Тегу"
                        type="text"
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={formState.tag_description}
                    />
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">
                            Скасувати
                        </Button>
                        <Button type="submit">
                            Додати Тег
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    );
}

export default ProductTagsCreate;