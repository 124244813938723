import React, { ReactNode } from 'react';
import {
    Route, Routes, Navigate, RouteProps
} from 'react-router-dom'
import Index from './containers/Index';
import Login from './containers/Login/index';
import PageLayout from './containers/PageLayout/index';
import Orders from './containers/Orders';
import Products from './containers/Products/index';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './components/LoadingScreen';
import Categories from './containers/Categories';
import ProductCreate from './containers/Products/ProductCreate';
import ProductEdit from './containers/Products/ProductEdit';
import FAQs from './containers/FAQs';
import Payments from './containers/Payments';
import Reviews from './containers/Reviews';
import ReviewsEdit from './containers/Reviews/ReviewsEdit/ReviewsEdit';
import Articles from './containers/Articles';
import Promocode from './containers/Promocode';

interface RouterProps  {
    exact?: boolean;
    path: string;
}

const Router: React.FC = (props) => {
    const { isAuthenticated, isLoading } = useAuth0();

    const RequireAuth = ({ children, redirectTo }) => {
        return isAuthenticated ? children : <Navigate to={redirectTo} />;
    }

    return (
        <>
        {isLoading ? (
            <Loading />
        ) : (
        isAuthenticated ? (
        <Routes>
            <Route path="/" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Головна">
                        <Index />
                    </PageLayout>
                </RequireAuth>
            } />
              
            <Route path="/orders" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Замовлення">
                        <Orders />
                    </PageLayout>
                </RequireAuth>
            } />
                
            <Route path="/products" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Товари">
                        <Products />
                    </PageLayout>
                </RequireAuth>
            }/>
                
            <Route path="/faqs" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="FAQs">
                        <FAQs />
                    </PageLayout>
                </RequireAuth>
            }/>

            <Route  path="/payments" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Платежі">
                        <Payments />
                    </PageLayout>
                </RequireAuth>
            }/>
                
            <Route path="/products/create" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Створити товар">
                        <ProductCreate />
                    </PageLayout>
                </RequireAuth>
            }/>

            <Route path="/products/edit/:id" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Редагувати товар">
                        <ProductEdit />
                    </PageLayout>
                </RequireAuth>
            }/>
             
            <Route path="/categories" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Категорії">
                        <Categories />
                    </PageLayout>
                </RequireAuth>
            } />
            
            <Route path="/reviews" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Відгуки">
                        <Reviews />
                    </PageLayout>
                </RequireAuth>
            } />
            <Route path="/articles" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Статті">
                        <Articles />
                    </PageLayout>
                </RequireAuth>
            } />
            <Route path="/review/edit/:id" element={
                <RequireAuth redirectTo="/login">
                    <PageLayout pageTitle="Редагувати відгук">
                        <ReviewsEdit />
                    </PageLayout>
                </RequireAuth>
            }/>
            <Route path="/promocodes"
                element={
                    <RequireAuth redirectTo='/login'>
                        <PageLayout pageTitle='Промокоди'>
                            <Promocode />
                        </PageLayout>
                    </RequireAuth>
                }
            />
            <Route path="/*" element={
                <RequireAuth redirectTo="/">
                    <Navigate to="/" />
                </RequireAuth>
            }/>
            </Routes>
        ) : (
            <Routes>
                <Route path="/*" element={<Login/>} />
            </Routes>
        )
        )}
        </>
    );  
};

export default Router;