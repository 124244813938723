import React, { useState } from 'react';
import Header from '../../components/Header/index';
import Sidebar from '../Sidebar/index';
import classes from './PageLayout.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';

interface PageLayoutProps {
    pageTitle: string;
}

const PageLayout:React.FC<PageLayoutProps> = props => {
    const {  pageTitle, children } = props;
    const [drawerState, setOpen] = useState(false);
    const matches = useMediaQuery(`(max-width:600px)`);
    
    return( 
        <div className={classes.page_layout_main}>
            <Header drawerOpen={(state) => setOpen(state)} />    
            <div className={classes.page_layout_content}>
                {/* Only show Sidebar from under 600px screen width */}
                <Sidebar 
                    open={drawerState} 
                    responseState={matches}
                    onClose={() => setOpen(false)} 
                />        
                <div className={classes.page_layout_children}>  
                    <div className={classes.page_layout_children__title}>
                        {pageTitle}
                    </div> 
                    <div className={classes.page_layout_children__content}>
                        {children}
                    </div>
                </div>               
            </div>
        </div>
    );
};

export default PageLayout;