import React from 'react';
import '../Products.scss';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';
import { styled } from '@mui/styles';
import { httpRequest } from '../../../utils';

const DeleteIcon = styled(IconButton)({
    position: 'absolute',
    color: 'white'
    
})

interface IProductImages {
    imgArr: IImage[];
}

interface IImage {
    name?: string;
    src: string;
    alt: string;
    id: number;
}

const ProductImages:React.FC<IProductImages> = (props) => {
    const { imgArr } = props;
    
    const handleImgDelete = (id: number) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/product-image/${id}`)
            .then(res => {
                
            })
            .catch(err => {
                console.log(err)
            })
    }
    return(
        <>
        <h5>Зображення товару</h5>
        <div className="product-images_list">
            {imgArr.map(img => (
                <span className="img-main">
                    <DeleteIcon onClick={() => handleImgDelete(img.id)} disableFocusRipple edge={false} size="small">
                        <HighlightOffIcon />
                    </DeleteIcon>
                    <img src={img.src} alt={img.alt} />
                </span>
            ))}
        </div>
        </>
    );
};

export default ProductImages;