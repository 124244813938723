import axios, { Method } from 'axios';
import { AuthService } from '../services/AuthService';

export async function httpRequest(method: Method, path: string, data?: any) {
    const authService = new AuthService();
    const accessToken = JSON.parse(await authService.getLocalStorageItem('accessToken'));
    
    if (accessToken) {
        const res: Promise<any> = await axios({
            method: method,
            url: path,
            data: data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.log(err);
        })
        return res;
    }

    
}