import React from 'react';
import { ReactComponent as LogoImg } from '../../assets/logo.svg';
import { styled } from '@mui/material'

const LogoSVG = styled(LogoImg)({
    width: '240px'
})

const Logo = () => {
    return(
        <LogoSVG className="logo-svg" />
    );
};

export default Logo;