import React from 'react';
import {
    Button
} from '@mui/material';
import LoginLayout from '../LoginLayout';
import InputCard from '../../components/InputCard';
import { useAuth0 } from '@auth0/auth0-react';

import './Login.scss';



const Login: React.FC = (props) => {
    const { loginWithRedirect } = useAuth0();
    const handleLogin = (event: any) => {
        event.preventDefault();
        loginWithRedirect();
    }
    return(
        <LoginLayout>
            <InputCard title="Вхід">
                <form className="login-form">
                    <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        type="submit"
                        onClick={handleLogin}
                        >
                        Увійти
                    </Button>
                    <div className="login-socials">
                    </div>
                </form>
            </InputCard>    
        </LoginLayout>
    );
};
export default Login;