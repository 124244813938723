import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogContentText, DialogTitle, DialogContent,
    TextField, Select, MenuItem,
    DialogActions, Button, SelectChangeEvent
} from '@mui/material';
import { ICategory } from '../../types/index';
import { httpRequest } from '../../utils';
import { CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

interface CreationalModalProps {
    dialogTitle?: string;
    dialogText?: string;
    disable?: boolean;
    categoriesData?: ICategory[];
    type?: 'edit' | 'create';
    categoryData?: ICategory;
    onRefresh: () => void;
}

const CreationModal:React.FC<CreationalModalProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [formState, setFormState] = useState({
        id: '',
        name: '',
        slug: '',
        description: '',
        parent: null,
        meta_title: '',
        meta_description: '',
        category_banner: ''
    });
    
    const { dialogTitle, dialogText, disable, categoriesData, type, categoryData, onRefresh } = props;

    useEffect(() => {
        if (type === 'edit' && categoryData) {  
            setFormState((prevState: any) => ({
                ...prevState,
                id: categoryData.id,
                name: categoryData.name,
                slug: categoryData.slug,
                description: categoryData.description,
                meta_description: categoryData.meta_description,
                meta_title: categoryData.meta_title,
                category_banner: categoryData.category_banner
            }))

            if (categoryData.parent) {
                setFormState((prevState: any) => ({
                    ...prevState,
                    parent: categoryData.parent!.id
                }))
            }
        }
    }, [type])

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const name = event.target.name;

        setFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSelect = (event: SelectChangeEvent<any>) => {
        const value = event.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            parent: value
        }));
    }

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (type === 'create') {
            httpRequest('POST', `${process.env.REACT_APP_API}/categories`, {...formState})
            .then(res => {
                onRefresh();
            })
            .catch(err => console.log(err));
        }
        if (type === 'edit') {
            httpRequest('PATCH', `${process.env.REACT_APP_API}/categories/${formState.id}`, { ...formState })
                .then(res => {
                    onRefresh();
                })
                .catch(err => console.log(err));
        }   
        
    }

    const handleDescriptionChange = (data) => {
        setFormState((prevState: any) => ({
            ...prevState,
            description: data
        }))
    }

    return(
        <div>
            <Button disabled={disable} onClick={handleOpen}>
                {type === 'create' && 'Створити'}
                {type === 'edit' && 'Редагувати'}
            </Button>
            <Dialog
                open={open}
                disableEnforceFocus 
                onClose={handleClose}
                aria-labelledby="form-dialog-title"> 
                <form onSubmit={handleFormSubmit}>
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent >
                        <DialogContentText>
                            {dialogText}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            variant="outlined"
                            required={true}
                            margin="dense"
                            id="name"
                            label="Назва категорії"
                            type="text"
                            name="name"
                            value={formState.name}
                            fullWidth
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            required={true}
                            margin="dense"
                            id="slug"
                            label="Коротке посилання"
                            type="text"
                            name="slug"
                            value={formState.slug}
                            fullWidth
                            onChange={handleChange}
                        />
                        <TextField
                            required={false}
                            variant="outlined"
                            id="category_banner"
                            label="Банер категорії"
                            multiline
                            rows={2}
                            name="category_banner"
                            margin="normal"
                            value={formState.category_banner}
                            fullWidth
                            onChange={handleChange}
                        />
                        <div>
                            <h4>Опис категорії</h4>
                            <CKEditor
                                editor={Editor}
                                name="cdescription"
                                height={500}
                                data={formState.description || ''}
                                onChange={(event, editor) => {
                                    const data = editor.getData()
                                    handleDescriptionChange(data)
                                }}
                            />
                        </div>
                        {/* <TextField
                            required={false}
                            variant="outlined"
                            id="cdescription"
                            label="Опис категорії"
                            margin="normal"
                            multiline
                            rows={2}
                            name="description"
                            value={formState.description}
                            fullWidth
                            onChange={handleChange}
                        /> */}
                        <TextField
                            required={false}
                            variant="outlined"
                            id="meta_title"
                            label="Meta Title"
                            multiline
                            margin="normal"
                            rows={2}
                            name="meta_title"
                            value={formState.meta_title}
                            fullWidth
                            onChange={handleChange}
                        />
                        <TextField
                            required={false}
                            variant="outlined"
                            id="meta_description"
                            label="Meta  Description"
                            multiline
                            rows={4}
                            margin="normal"
                            name="meta_description"
                            value={formState.meta_description}
                            fullWidth
                            onChange={handleChange}
                        />
                        <div className="category-label">
                            Батьківська Категорія
                        </div>
                        <Select
                            labelId="category-select"
                            id="category-select"
                            name="parent_category"
                            fullWidth
                            value={formState.parent}
                            variant="outlined"
                            onChange={(e) => handleSelect(e)}>
                                {(typeof(categoriesData) !== 'undefined') && (
                                    categoriesData.map(cat => (
                                        <MenuItem 
                                        key={cat.id}
                                        value={cat.id}>
                                            {cat.name}
                                        </MenuItem>
                                    ))
                                )}
                        </Select>
                    </DialogContent>
                    <DialogActions color="secondary">
                        <Button onClick={handleClose}>
                            Скасувати   
                        </Button>
                        <Button onClick={handleClose} color="secondary" type="submit">
                            {type === 'create' && 'Створити'}
                            {type === 'edit' && 'Оновити'} 
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default CreationModal;