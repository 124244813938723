import React, { useState } from 'react';
import {
    TableRow, TableCell,
    IconButton, Button, Divider
} from '@mui/material';
import { IOrder } from '../../types';
import { formatDate } from '../../utils/formatData';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styles from './OrderItem.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import { httpRequest } from '../../utils/index';
import OrderSelect from './OrderSelect';

const OrderItem = (props) => {
    const { order, refresh } = props;
    const [expandState, setExpandState] = useState<boolean>(false);

    console.log(order)

    const handleExpand = () => {
        setExpandState((prevState: boolean) => !prevState)
    }
    const handleOrderDelete = (id) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/orders/${id}`);
        refresh();
    }

    return <>
        <TableRow hover>
             <TableCell>#{order.id} - {formatDate(order.date_created, 'LLL')}</TableCell>
            <TableCell>{order.shipping.first_name + ' ' + order.shipping.last_name} </TableCell>
            <TableCell>{order.shipping.phone}</TableCell>
            <TableCell>{order.shipping.email}</TableCell>
            <TableCell>{order.shipping.city}</TableCell>
            <TableCell>{order.total} грн</TableCell>
            <TableCell>
                {order.status === 'pending' && <p style={{color: 'blue'}}>В обробці</p>}
            </TableCell>
            <TableCell>
                <IconButton onClick={handleExpand} size="large">
                    {!expandState ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
            </TableCell>
        </TableRow>
        {expandState && (
            <>
                <TableCell colSpan={2} variant="footer">
                    <OrderSelect
                        label="Статус"
                    />
                    <div>
                        <p>Відділення: {order.shipping.warehouse}</p>
                        <p>Місто: {order.shipping.city}</p>
                    </div>
                </TableCell>
                <TableCell>
                    
                </TableCell>
                    
                    <Button
                    startIcon={<DeleteIcon />}
                    style={{fontSize: '12px', padding: '5px'}}
                    variant="outlined"
                    onClick={() => handleOrderDelete(order.id)}>
                        Видалити замовлення
                    </Button>

            </>
        )}
    </>;
};

export default OrderItem;