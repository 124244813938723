import React from 'react';
import {
    Drawer
} from '@mui/material';
import SidebarMenu from './SidebarMenu/index';
import './Sidebar.scss';

interface SidebarProps {
    open: boolean;
    onClose: () => void;
    responseState: boolean;
}

const Sidebar:React.FC<SidebarProps> = (props) => {
    const { open, onClose, responseState} = props;

    return(
        <Drawer
            open={open}
            onClose={onClose}
            variant={responseState ? 'temporary' : 'permanent'}
            ModalProps={{
                keepMounted: true,
                disablePortal: true
            }}
            BackdropProps={{ invisible: true }}
            PaperProps={{
                style: {
                    top: '59px',
                    padding: '0px 0 0 0px',
                    zIndex: 999,
                    width: '240px'
                }
            }}
            elevation={2}
            anchor="left">
            <SidebarMenu onClose={onClose} />
        </Drawer>
    );
}

export default Sidebar;