import {  } from '@auth0/auth0-react';

class AuthService<T> {
    userData: T | null;
    constructor() {
        this.userData = null;
    }

    async getUserProfileData(user: T, options: {isAuthenticated: boolean, isLoading: boolean}) {
        const userData = new Promise((resolve, reject) => {
        try {
            if (!options.isLoading) {
                if (options.isAuthenticated) {
                    this.userData = user;
                    resolve(user);
                } else {
                    resolve(false);
                }
                reject('User not authenticated');
            } 
        } catch (error) {
                reject(error);
            }
        });
        const response = await userData;
        return response;
    }

    setLocalStorageItem<T>(name: string, data: T) {
        localStorage.setItem(name, JSON.stringify(data));
    }

    getLocalStorageItem(name: string) {
        return new Promise<string>((resolve, reject) => {
            try {
                let item = localStorage.getItem(name);
                if (item) {
                    resolve(item);
                }
                
            } catch (err) {
                reject(err);
            }
        })
    }
}

export { AuthService };