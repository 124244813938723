import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent,
    DialogActions, TextField, Button,
    List, ListItem, ListItemText, ListItemIcon,
    Divider
} from '@mui/material';
import '../Products.scss';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { httpRequest } from '../../../utils';

interface ProductAttributesCreateProps {
    productId: string;
    prodAttr: ProductAttribute[];
    openDialog: () => void;
    updateState: () => void;
}

interface ProductAttribute {
    attribute_name: string;
    attribute_value: string;
    attribute_category: string
    product: string;
    id: string;
}

interface ProductAttributesDialogProps {
    open: boolean;
    onClose: () => void;
    productId: string;
    updateState: () => void;
}

export const ProductAttributesDialog:React.FC<ProductAttributesDialogProps> = (props) => {
    const { open, onClose, productId, updateState } = props;
    const [formState, setFormState] = useState({
        attribute_name: '',
        attribute_value: '',
        attribute_category: '',
        product: productId
    })
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    };
    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        httpRequest('POST', `${process.env.REACT_APP_API}/product-attributes`, { ...formState })
            .then(res => {
                updateState();
                onClose();
            })
            .catch(err => {
                console.log(err);
            });
    }
    return (
        <Dialog 
        open={open}
        onClose={onClose}>
            <form onSubmit={handleFormSubmit}>
                <DialogTitle>Додайте нові атрибути товару</DialogTitle>
                <DialogContent>
                    <TextField
                        id="attribute_name"
                        name="attribute_name"
                        variant="outlined"
                        label="Назва атрибуту"
                        type="text"
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={formState.attribute_name}
                    />
                    <TextField
                        id="attribute_value"
                        name="attribute_value"
                        variant="outlined"
                        label="Значення атрибуту"
                        type="text"
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={formState.attribute_value}
                    />
                    <TextField
                        id="attribute_category"
                        name="attribute_category"
                        variant="outlined"
                        label="Категорія атрибуту"
                        type="text"
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={formState.attribute_category}
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                    color="secondary" 
                    onClick={onClose}
                    >
                        Скасувати
                    </Button>
                    <Button
                    type="submit">
                        Додати
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

const ProductAttributesCreate:React.FC<ProductAttributesCreateProps> = (props) => {
    const { prodAttr, openDialog, updateState } = props;
    const handleAttrClick = () => {
        openDialog();
    }

    const handleDelete = (id: string) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/product-attributes/${id}`)
            .then(res => {
                updateState();
            })
            .catch(err => {
                console.log(err);
            })
    }
    return(
        <div className="product-attributes">
            {prodAttr.length > 0 && (
            <div className="product-attributes_list">       
                <h5>Аттрибути товару</h5>
                <List>
                {prodAttr.map(attr => (
                    <>
                    <ListItem key={attr.id} button>
                        <ListItemText primary={attr.attribute_name} />
                        <ListItemText primary={attr.attribute_value} />
                        <ListItemIcon onClick={() => handleDelete(attr.id)}>
                            <HighlightOffIcon color="secondary" />
                        </ListItemIcon>
                    </ListItem> 
                    <Divider key={`${attr.id}-divier`} />
                    </>
                ))}
                </List>
            </div>
            )}
            <Button 
            variant="contained" 
            onClick={handleAttrClick}>
                Додати новий атрибут товару
            </Button>
            
        </div>
    );
};

export default ProductAttributesCreate;