import styles from '../Reviews.module.scss';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../hooks/useApi';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Paper, TextField } from '@mui/material';
import { httpRequest } from '../../../utils';

const ReviewsEdit = () => {
    let { id } = useParams();
    const { loading, data, refresh } = useApi('GET', `${process.env.REACT_APP_API}/reviews/edit/${id}`);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: null,
        advantagesText: '',
        approved: false,
        commentText: '',
        disadvantagesText: '',
        review_date_created: '',
        review_rating: 5,
        youtube_link: '',
    })

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        })) 
    }


    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        httpRequest('PATCH', `${process.env.REACT_APP_API}/reviews/${id}`, {
            ...formData,
        })
        .then(res => {
            navigate('/review');
        })
    }

    useEffect(() => {
        if (!loading) {
            setFormData((prevState) => ({
                ...prevState,
                id: data.id,
                advantagesText: data.advantagesText,
                approved: data.approved,
                commentText: data.commentText,
                disadvantagesText: data.disadvantagesText,
                review_date_created: data.review_date_created,
                review_rating: data.review_rating,
                youtube_link: data.youtube_link,
            }))
        }
    }, [loading, data])

    return(
        <Paper>
            <div className={styles.reviews_container}>
                <form className={styles.review_edit__form} onSubmit={handleUpdateSubmit}>
                    <TextField
                        id="id"
                        name="id"
                        value={formData.id}
                        variant="outlined"
                        disabled
                        type="number"
                        margin="normal" 
                        label="ID товару"
                        onChange={handleChange}
                    />
                    <TextField
                        id="advantagesText"
                        name="advantagesText"
                        value={formData.advantagesText}
                        variant="outlined"
                        type="text"
                        disabled
                        margin="normal" 
                        label="Переваги товару"
                        onChange={handleChange}
                    />
                    <TextField
                        id="commentText"
                        name="commentText"
                        value={formData.commentText}
                        variant="outlined"
                        type="text"
                        multiline
                        minRows={4}
                        maxRows={6}
                        margin="normal" 
                        label="Коментар"
                        onChange={handleChange}
                    />
                    <TextField
                        id="disadvantagesText"
                        name="disadvantagesText"
                        value={formData.disadvantagesText}
                        variant="outlined"
                        type="text"
                        disabled
                        margin="normal" 
                        label="Недоліки"
                        onChange={handleChange}
                    />
                    <TextField
                        id="review_date_created"
                        name="review_date_created"
                        value={formData.review_date_created}
                        variant="outlined"
                        type="text"
                        margin="normal" 
                        label=""
                        onChange={handleChange}
                    />
                    <TextField
                        id="review_rating"
                        name="review_rating"
                        value={formData.review_rating}
                        variant="outlined"
                        type="text"
                        margin="normal" 
                        label="Рейтинг"
                        onChange={handleChange}
                    />
                    <TextField
                        id="youtube_link"
                        name="youtube_link"
                        value={formData.youtube_link}
                        variant="outlined"
                        type="text"
                        margin="normal" 
                        label="youtube_link"
                        onChange={handleChange}
                    />
                    <Button type="submit" color="primary" variant="contained" fullWidth>
                        Оновити
                    </Button>
                </form>
            </div>
        </Paper>
    );
}

export default ReviewsEdit;