import {
    CLEAR_ROUTES,
SET_ROUTE
} from '../actions/actionTypes';

const initialState = {
    route: '/'
}

export default function routeReducer(state = initialState, action: any) {
    switch(action.type) {
        case SET_ROUTE: {
            return {
                ...state,
                route: action.path
            }
        }
        case CLEAR_ROUTES: {
            return {
                ...state,
                route: '/'
            }
        }
        default: 
            return state;
    }
}