import React, { useState, useEffect } from 'react';
import {
    Paper, TextField, MenuItem, Button,
    FormLabel, RadioGroup, FormControlLabel,
    Radio, Dialog, DialogActions, DialogContent ,
    DialogTitle, Chip, InputBase, Typography
    
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../../hooks/useApi';
import { ICategory, IProduct } from '../../../types/index';
import { httpRequest } from '../../../utils/index';
import '../Products.scss';
import ProductDeleteDialog from '../ProductDeleteDialog';
import ProductImageCreate from '../ProductImageCreate';
import ProductImages from '../ProductImageCreate/ProductImages';
import ProductTagsCreate from '../ProductTagsCreate';
import ProductTags from '../ProductTagsCreate/ProductTags';
import ProductAttributesCreate from '../ProductAttributesCreate';
import { ProductAttributesDialog } from '../ProductAttributesCreate';
import { CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const ProductEdit:React.FC = () => {
    let { id } = useParams<{id: any}>();
    
    let navigate = useNavigate();
    const [categoriesData, setCategoriesData] = useState([]);
    const [variationCreation, setVariationCreation] = useState({
        status: false,
        id: undefined
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [openImgDialog, setOpenImgDialog] = useState(false);
    const [openTagDialog, setOpenTagDialog] = useState(false);
    const [openAttrDialog, setOpenAttrDialog] = useState(false);
    
    const [formState, setFormState] = useState<IProduct>({
        id: null,
        product_number: null,
        name: '',
        slug: '',
        category: undefined,
        type: '',
        price_sale: 'no_sale',
        brand: '',
        price: undefined,
        sale_price: null,
        description: '',
        sku: '',
        imgUrl: '',
        meta_title: '',
        meta_description: '',
        status: 'instock',
        product_variation_title: '',
        shipping_price: 'free-shipping'
    });

    const [variableFormState, setVarFormState] = useState<IProduct>({
        id: null,
        name: '',
        price: null,
        sku: '',
        sale_price: null,
        imgUrl: '',
        status: 'instock',
        product_number: '',
        price_sale: 'no_sale',
        slug: '',
        shipping_price: 'free-shipping',
        parentId: null,
        variation_type: null,
        colorCode: null
    });

    const [productVariants, setProductVariants] = useState({
        product_variants: []
    })
    
    const [productImages, setProductImages] = useState([]);
    const [productAttributes, setProductAttribute] = useState([]);
    const [productTags, setProductTags] = useState([]);
    const [deleteDialogState, setDeleteDialogState] = useState<boolean>(false);

    const { loading, data, refresh } = useApi('GET', `${process.env.REACT_APP_API}/products/${id}`);   

    useEffect(() => {
        if (!loading) {
            setFormState((prevState: IProduct) => ({
                ...prevState,
                id: data.id,
                product_number: data.product_number,
                name: data.name,
                slug: data.slug,
                category: data.category.id,
                type: data.type,
                price: data.price,
                sale_price: data.sale_price,
                sku: data.sku,
                imgUrl: data.imgUrl,
                description: data.description,
                meta_title: data.meta_title,
                meta_description: data.meta_description,
                brand: data.brand,
                status: data.status,
                product_variation_title: data.product_variation_title,
                shipping_price: data.shipping_price
            }))
            
            const nestedChildren = () => {
                let nestedArr: Array<any> = [];

                for (let i = 0; i < data.children.length; i++) {
                    if (data.children[i].children.length > 0) {
                        nestedArr = [...nestedArr, ...data.children[i].children]
                    }
                }

                return nestedArr;
            }
            
            setProductVariants((prevState: any) => ({
                ...prevState,
                product_variants: [
                    ...data.children,
                    ...nestedChildren()
                ]
            }))
                
            if (data.sale_price) {
                setFormState((prevState: any) => ({
                    ...prevState,
                    price_sale: 'sale'
                }))
            }
            setProductImages(data.product_images);
            setProductTags(data.product_tags);
            setProductAttribute(data.product_attributes)
            httpRequest('GET', `${process.env.REACT_APP_API}/categories`)
                .then(res => {
                    setCategoriesData(res);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [loading, data, variationCreation.status]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleVariableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;


        
        // if (variableFormState.sale_price) {
        //     setVarFormState((prevState: any) => ({
        //         ...prevState,
        //         price_sale: 'sale'
        //     }))
        // }
        
        setVarFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
        if (name === 'sale_price' && value === '') {
            setVarFormState((prevState: any) => ({
                ...prevState,
                sale_price: null
            }))
        }
    }

    const handleSelect = (event: React.ChangeEvent<{ value: unknown}>) => {
        const value = event.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            category: value
        }))
    }

    const handleStatusSelect = (event: React.ChangeEvent<{ value: unknown}>) => {

        const value = event?.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            status: value
        }))
    }

    const handleVariationOpen = () => {
        setVarFormState((prevState: any) => ({
            ...prevState,
            name: '',
            price: null,
            sku: '',
            sale_price: null,
            status: '',
            imgUrl: '',
            product_number: '',
            slug: '',
            price_sale: 'no_sale'
        }));
        setVariationCreation((prevState: any) => ({
            ...prevState,
            status: true,
            id: ''
        }));
        setOpenDialog(true);
    }

    const handleVariationClose = () => {
        setOpenDialog(false);
    }

    const handleDescriptionChange = (data) => {
        setFormState((prevState: any) => ({
            ...prevState,
            description: data
        }))
    }

    const handleVariationSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault(); 
        // Creates new products variation;
        if (variationCreation.status) { 
            httpRequest('POST', `${process.env.REACT_APP_API}/products`, {
                name: variableFormState.name,
                slug: variableFormState.slug,
                category: formState.category,
                type: 'variation',
                price: variableFormState.price,
                sku: variableFormState.sku,
                brand: formState.brand,
                sale_price: variableFormState.sale_price ?? null,
                description: formState.description,
                imgUrl: variableFormState.imgUrl,
                meta_title: formState.meta_title,
                meta_desciption: formState.meta_description,
                status: variableFormState.status,
                product_number: variableFormState.product_number,
                parentId: variableFormState.parentId ?? formState.id,
                product_variation_title: formState.product_variation_title,
                price_sale: variableFormState.price_sale,
                shipping_price: variableFormState.shipping_price,
                colorCode: variableFormState.colorCode,
                variation_type: variableFormState.variation_type
            }
            )
            .then(res => {
                setOpenDialog(false);
                refresh();
            })
            .catch(err => {
                console.log(err);
                setOpenDialog(false)
            })
        } else {
            console.log(variableFormState)
            // Updated Products variation
            httpRequest('PATCH', `${process.env.REACT_APP_API}/products/${variationCreation.id}`, variableFormState)
                .then((res) => {
                    setOpenDialog(false);
                    refresh();
                })
                .catch(err => console.log(err))
        }
        
    }

    const handleUpdateSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        httpRequest('PATCH', `${process.env.REACT_APP_API}/products/${id}`, formState)
            .then(res => {
                navigate('/products')
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleChipClick = (product_variation: any) => {
        setVarFormState((prevState: any) => ({
            ...prevState,
                id: product_variation.id,
                name: product_variation.name,
                slug: product_variation.slug,
                price: product_variation.price,
                sku: product_variation.sku,
                brand: product_variation.brand,
                sale_price: product_variation.sale_price,
                imgUrl: product_variation.imgUrl,
                status: product_variation.status,
                product_number: product_variation.product_number,
                parentId: product_variation.parentId ?? formState.id,
                price_sale: product_variation.price_sale,
                shipping_price: product_variation.shipping_price,
                colorCode: product_variation.colorCode, 
                variation_type: product_variation.variation_type
        }));
        setVariationCreation({
            status: false,
            id: product_variation.id
        });
        setOpenDialog(true);
    }

    const handleChipClose = (id: string) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/products/${id}`)
            .then(res => {
                setVariationCreation({
                    status: false,
                    id: undefined
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDeleteDialogOpen = () => {
        setDeleteDialogState(true);
    }

    const handleDeleteDialogClose = () => {
        setDeleteDialogState(false);
    }

    const handleImgDialogState = (status: string) => {
        if (status === 'close') {
            setOpenImgDialog(false);
        } else if (status === 'open') {
            setOpenImgDialog(true);
        }
    }

    const handleTagDialogStateOpen = () => {
        setOpenTagDialog(true);
    }

    const handleTagDialogStateClose = () => {
        setOpenTagDialog(false);
    }

    const handlePriceTypeChange = (event: React.ChangeEvent<{ value: unknown}>) => {
        const value = event.target.value;
        if (value === 'no_sale') {
            setFormState((prevState: any) => ({
                ...prevState,
                price_sale: value,
                sale_price: null
            }))
        } else {
            setFormState((prevState: any) => ({
                ...prevState,
                price_sale: value
            }))
        }
        
    }

    return(
        <Paper>
            <div className="product-edit-page_main">
                <div className="product-edit-page_actions">
                    <Button
                        variant="outlined"
                        endIcon={<DeleteOutlineIcon />}
                        onClick={handleDeleteDialogOpen}>
                        Видалити товар
                    </Button>
                </div>
                <form className="product-edit_form" onSubmit={handleUpdateSubmit}>
                    <TextField
                        id="product_number"
                        name="product_number"
                        value={formState.product_number}
                        variant="outlined"
                        type="text"
                        margin="normal" 
                        label="ID товару"
                        onChange={handleChange}
                    />
                    <TextField
                        id="name"
                        name="name"
                        value={formState.name}
                        variant="outlined"
                        type="text"
                        margin="normal" 
                        label="Назва товару"
                        onChange={handleChange}
                    />
                    <TextField
                        id="slug"
                        label="Коротке посилання"
                        placeholder="/productName"
                        name="slug"
                        value={formState.slug}
                        variant="outlined"
                        margin="normal"
                        type="text"
                        onChange={handleChange}
                    />
                    <TextField
                        id="brand"
                        label="Бренд"
                        name="brand"
                        value={formState.brand}
                        variant="outlined"
                        margin="normal"
                        type="text"
                        onChange={handleChange}
                    />
                    <TextField
                        id="select-avb"
                        label="Наявність"
                        select
                        variant="outlined"
                        margin="normal"
                        onChange={handleStatusSelect}
                        value={formState.status}>
                        <MenuItem key={`status-instock`} value="instock">В наявості</MenuItem>
                        <MenuItem key={`status-outofstock`} value="outofstock">Немає в наявності</MenuItem>
                    </TextField>
                    <TextField
                        id="select"
                        label="Категорія"
                        select
                        variant="outlined"
                        margin="normal"
                        onChange={handleSelect}
                        value={formState.category}>
                            {(typeof(categoriesData) !== 'undefined') && (                                
                                categoriesData.map((cat: ICategory) => (                               
                                    <MenuItem 
                                    key={cat.id}
                                    value={cat.id}
                                    >
                                        {cat.name}
                                    </MenuItem>
                                ))
                            )}
                    </TextField>
                    <div className="product-shipping_select">
                        <FormLabel component="legend">Вартість доставки</FormLabel>
                        <RadioGroup value={formState.shipping_price} name="shipping_price" aria-label="shipping_price" onChange={handleChange}>
                            <FormControlLabel value="free-shipping" control={<Radio />} label="Безкоштовна"></FormControlLabel>
                            <FormControlLabel value="no-free-shipping" control={<Radio />} label="За тарифами перевізника"></FormControlLabel>
                        </RadioGroup>
                    </div>
                    {/* Product Attributes */} 
                    <ProductAttributesCreate 
                        productId={id} 
                        prodAttr={productAttributes}
                        openDialog={() => setOpenAttrDialog(true)} 
                        updateState={refresh} 
                    />

                    {/* Product images */}
                    <ProductImages imgArr={productImages} />
                    
                    {/* Product tags */}
                    <ProductTags tagsArr={productTags} />

                    <div className="product-type_select">
                        <FormLabel component="legend">Тип товару</FormLabel>
                        <RadioGroup value={formState.type} name="type" aria-label="type" onChange={handleChange}>
                            <FormControlLabel value="simple" control={<Radio />} label="Простий"></FormControlLabel>
                            <FormControlLabel value="variable" control={<Radio />} label="Варіативний"></FormControlLabel>
                        </RadioGroup>
                        <RadioGroup value={formState.price_sale} name="type" aria-label="type" onChange={handlePriceTypeChange}>
                            <FormControlLabel value="sale" control={<Radio />} label="Акційна ціна"></FormControlLabel>
                            <FormControlLabel value="no_sale" control={<Radio />} label="Без акціїї"></FormControlLabel>
                        </RadioGroup>
                    </div>
                    <div>
                        <Button style={{margin: 9}} onClick={() => handleImgDialogState('open')}  variant="contained">
                                    Додати зображення товару
                        </Button>
                        <Button onClick={handleTagDialogStateOpen} style={{margin: 9}}  variant="contained">
                            Додати Тег
                        </Button>
                    </div>
                    {formState.type === 'simple' ? (
                        <div>
                            <TextField
                                id="price"
                                label="Ціна"
                                value={formState.price}
                                name="price"
                                style={{margin: 9}}
                                margin="normal"
                                variant="filled"
                                type="number"
                                onChange={handleChange}
                            />
                            <TextField
                                id="price"
                                label="Ціна зі знижкою"
                                disabled={formState.price_sale === 'no_sale'}
                                value={formState.sale_price}
                                name="sale_price"
                                style={{margin: 9}}
                                margin="normal"
                                inputProps={{
                                    min: 0
                                }}
                                variant="filled"
                                type="number"
                                onChange={handleChange}
                            />
                            <TextField
                                id="sku"
                                label="SKU"
                                value={formState.sku}
                                name="sku"
                                style={{margin: 9}}
                                margin="normal"
                                variant="filled"
                                type="text"
                                onChange={handleChange}
                            />
                            <TextField
                                id="img"
                                label="Посилання на зображення"
                                value={formState.imgUrl}
                                name="imgUrl"
                                style={{margin: 9}}
                                margin="normal"
                                variant="filled"
                                type="text"
                                onChange={handleChange}
                            />
                        </div>
                    ) : (
                        // Product is variable
                        <>
                            <TextField
                                id="variation-name"
                                label="Назва варіації"
                                value={formState.product_variation_title}
                                name="product_variation_title"
                                style={{margin: 9}}
                                margin="normal"
                                variant="filled"
                                type="text"
                                onChange={handleChange}
                            />
                            <div className="variation-create_main">
                                <div className="variation-list">
                                    {(productVariants.product_variants) && productVariants.product_variants.map((variant: any) => (
                                        <Chip 
                                            color="secondary" 
                                            style={{margin: 7}} 
                                            label={variant.id} 
                                            onClick={() => handleChipClick(variant)}
                                            onDelete={() => handleChipClose(variant.id)}
                                        />
                                    ))}
                                </div>
                                <Button style={{margin: 9}} onClick={handleVariationOpen} variant="contained">       
                                    Додати варіацію
                                </Button>
                            </div>
                        </>
                    )}
                    <Paper>
                        <div className="meta-props_main">
                            <Typography>SEO of Product</Typography>
                            <InputBase
                                fullWidth
                                id="meta_title"
                                name="meta_title"
                                style={{color: 'blue'}}
                                placeholder="Enter title"
                                onChange={handleChange}
                                type="text"
                                value={formState.meta_title}
                            />
                            <InputBase
                                fullWidth
                                id="meta_description"
                                name="meta_description"
                                multiline
                                rows={4}
                                placeholder="Enter description.."
                                onChange={handleChange}
                                type="text"
                                value={formState.meta_description}
                            />
                        </div>
                    </Paper>
                    <div className="description_editor__main" >
                            <h3>Опис товару</h3>
                            <CKEditor
                                editor={Editor}
                                name="description"
                                data={formState.description || ''}
                                onChange={(event, editor) => {
                                    const data = editor.getData()
                                    handleDescriptionChange(data)
                                }}
                                
                            />
                        </div>
                    <Button type="submit" color="secondary" variant="contained" fullWidth>
                        Оновити
                    </Button>
                </form>

                {/* Variable Creation Dialog */}
                <Dialog open={openDialog} onClose={handleVariationClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleVariationSubmit}>
                    <DialogTitle id="new-product-variable_creation-form">{variationCreation.status ? 'Створення нової варіації' : 'Оновити варіацію'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="product_number"
                            name="product_number"
                            value={variableFormState.product_number}
                            type="number"
                            margin="normal" 
                            label="ID товару"
                            onChange={handleVariableChange}
                        />
                        <TextField
                            id="variable-name"
                            name="name"
                            label="Назва варіації"
                            type="text"
                            fullWidth
                            onChange={handleVariableChange}
                            value={variableFormState.name}
                        />
                        <TextField
                            id="variation_type"
                            name="variation_type"
                            label="Тип варіації"
                            select
                            fullWidth
                            onChange={handleVariableChange}
                            value={variableFormState.variation_type}
                        >
                            <MenuItem value="color" key={1}>Color</MenuItem>
                            <MenuItem value="select" key={2}>Select</MenuItem>
                        </TextField>
                        <TextField
                            id="variable-price"
                            name="price"
                            label="Ціна"
                            type="number"
                            fullWidth
                            onChange={handleVariableChange}
                            value={variableFormState.price}
                        />
                        <TextField
                            id="variable-sku"
                            name="sku"
                            label="SKU"
                            type="text"
                            fullWidth
                            onChange={handleVariableChange}
                            value={variableFormState.sku}
                        />
                        {/* Select Parent Product if one exsist */}
                        <TextField
                            id="parent-product"
                            label="Parent product"
                            name="parentId"
                            fullWidth
                            select
                            margin="normal"
                            onChange={handleVariableChange}
                            value={variableFormState.parentId}>
                                {data && [...data.children, data].map(prods => (
                                    <MenuItem key={`${prods.id}-productVariant`} value={prods.id}>
                                        {prods.name}
                                    </MenuItem>
                                ))}
                                {/* Iterate possible product parents from variations */}
                                
                        </TextField>
                        <TextField
                            id="select-avb"
                            label="Наявність"
                            name="status"
                            fullWidth
                            select
                            margin="normal"
                            onChange={handleVariableChange}
                            value={variableFormState.status}>
                                <MenuItem key={`status-instock-var`} value="instock">В наявості</MenuItem>
                                <MenuItem key={`status-outofstock-var`} value="outofstock">Немає в наявності</MenuItem>
                        </TextField>
                        <TextField
                            id="variable-sale_price"
                            name="sale_price"
                            label="Акційна ціна"
                            type="number"
                            defaultValue={null}
                            fullWidth
                            onChange={handleVariableChange}
                            value={variableFormState.sale_price}
                        />
                        <TextField
                            id="variable-colorCode"
                            name="colorCode"
                            label="Код кольору, якщо тип Color"
                            type="text"
                            fullWidth
                            onChange={handleVariableChange}
                            value={variableFormState.colorCode}
                        />
                        <TextField
                            id="variable-img"
                            name="imgUrl"
                            label="Посилання на зображення"
                            type="text"
                            fullWidth
                            onChange={handleVariableChange}
                            value={variableFormState.imgUrl}
                        />
                        <TextField
                            id="variable-slug"
                            name="slug"
                            label="Url товару"
                            type="text"
                            fullWidth
                            onChange={handleVariableChange}
                            value={variableFormState.slug}
                        />
                        <div className="product-shipping_select">
                            <FormLabel component="legend">Вартість доставки</FormLabel>
                            <RadioGroup value={variableFormState.shipping_price} name="shipping_price" aria-label="shipping_price" onChange={handleVariableChange}>
                                <FormControlLabel value="free-shipping" control={<Radio />} label="Безкоштовна"></FormControlLabel>
                                <FormControlLabel value="no-free-shipping" control={<Radio />} label="За тарифами перевізника"></FormControlLabel>
                            </RadioGroup>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={handleVariationClose} >Скасувати</Button>
                        <Button  type="submit">{(variationCreation.status) ? 'Створити' : 'Оновити'}</Button>
                    </DialogActions>                                 
                    </form>
                </Dialog>
                <ProductDeleteDialog
                    open={deleteDialogState}
                    onClose={handleDeleteDialogClose}
                    productDeletedId={id}
                />
                <ProductAttributesDialog
                    updateState={refresh}
                    open={openAttrDialog}
                    onClose={() => setOpenAttrDialog(false)}
                    productId={id}
                />
                <ProductImageCreate
                    open={openImgDialog}
                    onClose={() => handleImgDialogState('close')}
                    productId={id}
                />
                <ProductTagsCreate
                    open={openTagDialog}
                    onClose={handleTagDialogStateClose}
                    productId={id}
                />
            </div>
        </Paper>  
    );
};

export default ProductEdit;