import React, { useEffect } from 'react';
import { AuthService } from '../../services/AuthService';
import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { setUserData, authStatus } from '../../redux/actions/auth';
import { AuthTypes } from '../../redux/actions/actionTypes';


interface DispatchProps {
    setUser: (data: any) => any,
    setAuthStatus: (arg: boolean) => boolean
}

type Props = DispatchProps;

const Index:React.FC<Props> = (props) => {
    const authService = new AuthService();
    const { user, isAuthenticated, isLoading } = useAuth0(); 
    
    useEffect(() => {
        authService.getUserProfileData(user, {isAuthenticated, isLoading})
            .then(result => {
                if (!result) {
                } else {
                    props.setAuthStatus(isAuthenticated);
                    props.setUser(user);
                }   
            })
            .catch(err => {
                console.log(err);
            })
    }, []); 

    return(
        <div className="welcoming-message">
            <h1>
                Welcome to the Chemiplast Admin website
            </h1>
        </div>
    );
};

const mapDispatchToProps = <T extends Function>(dispatch: T): DispatchProps => {
    return {
        setUser: (data: AuthTypes) => dispatch(setUserData(data)),
        setAuthStatus: (status: boolean) => dispatch(authStatus(status))
    }
}

export default connect(null, mapDispatchToProps)(Index);