import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent,
    TextField, Button,
    DialogActions
} from '@mui/material';
import { httpRequest } from '../../../utils';

interface IPorductImageCreate {
    open: boolean;
    onClose: (arg: string) => void;
    productId: string;
}

const ProductImageCreate:React.FC<IPorductImageCreate> = (props) => {
    const { open, onClose, productId } = props;
    const [formState, setFormState] = useState({
        name: '',
        src: '',
        alt: '',
        product: productId    
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    };

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        httpRequest('POST', `${process.env.REACT_APP_API}/product-image`, { ...formState })
            .then(res => {
                onClose('close');
            })
            .catch(err => {
                console.log(err);
            });
    }

    return(
        <Dialog 
        open={open}
        onClose={onClose}>
            <form onSubmit={handleFormSubmit}>
                <DialogTitle>Додайте нові зображення товару</DialogTitle>
                <DialogContent>
                    <TextField
                        id="name"
                        name="name"
                        variant="outlined"
                        label="Назва зображення"
                        type="text"
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={formState.name}
                    />
                    <TextField
                        id="src"
                        name="src"
                        margin="normal"
                        variant="outlined"
                        label="Посилання на зображення"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        value={formState.src}
                    />
                    <TextField
                        id="alt"
                        name="alt"
                        margin="normal"
                        variant="outlined"
                        label="Опис зображення"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        value={formState.alt}
                    />
                    
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => onClose('close')}>
                        Скасувати
                    </Button>
                    <Button type="submit">
                        Додати
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ProductImageCreate;