import React, { useState } from 'react';
import {
    FormControlLabel,
    Paper, RadioGroup, TextField, Radio, FormLabel, Button,
    MenuItem, InputBase, Typography
} from '@mui/material';
import { useApi } from '../../../hooks/useApi';
import { ICategory, IProduct } from '../../../types/index';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import '../Products.scss';

import { httpRequest } from '../../../utils';

const ProductCreate:React.FC = () => {
    const { data } = useApi<ICategory>('GET', `${process.env.REACT_APP_API}/categories`);
    const navigate = useNavigate();
    const [formState, setFormState] = useState({
        name: '',
        slug: '',
        price: null,
        sku: '',
        price_sale: 'no_sale',
        brand: '',
        type: 'simple',
        imgUrl: '',
        sale_price: null,
        description: '',
        permalink: '',
        meta_title: '',
        meta_description: '',
        category: 15
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        
        if (name === 'slug') {
            const slug = e.target.value;
            setFormState((prevState: any) => ({
                ...prevState,
                permalink: `https://chemiplast.ch/products/${slug}`
            }))
        }

        setFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        httpRequest('POST', `${process.env.REACT_APP_API}/products`, {
            ...formState
        })
        .then(() => {
            navigate('/products');
        })
    }

    const handleSelect = (event: React.ChangeEvent<{ value: unknown}>) => {
        const value = event.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            category: value
        }))
    }

    const handleDescriptionChange = (data) => {
        setFormState((prevState: any) => ({
            ...prevState,
            description: data
        }))
    }

    const handlePriceTypeChange = (event: React.ChangeEvent<{ value: unknown}>) => {
        const value = event.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            price_sale: value
        }))
    }

    return(
        <Paper>
            <div className="products-create_main">
                <form onSubmit={handleFormSubmit}>
                    <div className="products-create-input">
                    <TextField
                        id="filled-full-width"
                        label="Назва товару"
                        fullWidth
                        required
                        name="name"
                        value={formState.name}
                        margin="normal"
                        variant="filled"
                        type="text"
                        onChange={handleChange}
                    />
                    <TextField
                        id="select"
                        label="Категорія"
                        select
                        margin="normal"
                        onChange={handleSelect}
                        value={formState.category}>
                            {(typeof(data) !== 'undefined') && (                                
                                data.map((cat: ICategory) => (                               
                                    <MenuItem 
                                    key={cat.id}
                                    value={cat.id}
                                    >
                                        {cat.name}
                                    </MenuItem>
                                ))
                            )}
                    </TextField>
                    <TextField
                        id="slug"
                        label="Коротке посилання"
                        placeholder="/productName"
                        margin="normal"
                        name="slug"
                        value={formState.slug}
                        style={{margin: 9}}
                        variant="filled"
                        type="text"
                        onChange={handleChange}
                    />
                     <TextField
                        id="brand"
                        label="Бренд"
                        name="brand"
                        value={formState.brand}
                        margin="normal"
                        style={{margin: 9}}
                        variant="filled"
                        type="text"
                        onChange={handleChange}
                    />
                    <div className="product-type_select">
                        <FormLabel component="legend">Тип товару</FormLabel>
                        <RadioGroup value={formState.type} name="type" aria-label="type" onChange={handleChange}>
                            <FormControlLabel value="simple" control={<Radio />} label="Простий"></FormControlLabel>
                            <FormControlLabel value="variable" control={<Radio />} label="Варіативний"></FormControlLabel>
                        </RadioGroup>
                        <RadioGroup value={formState.price_sale} name="type" aria-label="type" onChange={handlePriceTypeChange}>
                            <FormControlLabel value="sale" control={<Radio />} label="Акційна ціна"></FormControlLabel>
                            <FormControlLabel value="no_sale" control={<Radio />} label="Без акціїї"></FormControlLabel>
                        </RadioGroup>
                    </div>
                    {formState.type === 'simple' && (
                    <>
                    <TextField
                        id="price"
                        label="Ціна"
                        value={formState.price}
                        name="price"
                        style={{margin: 9}}
                        margin="normal"
                        variant="filled"
                        type="number"
                        onChange={handleChange}
                    />
                    <TextField
                        id="sale_price"
                        disabled={formState.price_sale === 'no_sale'}
                        label="Ціна зі знижкою"
                        value={formState.sale_price}
                        name="sale_price"
                        style={{margin: 9}}
                        margin="normal"
                        variant="filled"
                        type="number"
                        onChange={handleChange}
                    />
                    <TextField
                        id="sku"
                        label="SKU"
                        value={formState.sku}
                        name="sku"
                        style={{margin: 9}}
                        margin="normal"
                        variant="filled"
                        type="text"
                        onChange={handleChange}
                    />
                    <TextField
                        id="img"
                        label="Посилання на зображення"
                        value={formState.imgUrl}
                        name="imgUrl"
                        style={{margin: 9}}
                        margin="normal"
                        variant="filled"
                        type="text"
                        onChange={handleChange}
                    />
                    </>)}
                    <Paper>
                        <div className="meta-props_main">
                            <Typography>SEO of Product</Typography>
                            <InputBase
                                fullWidth
                                id="meta_title"
                                name="meta_title"
                                style={{color: 'blue'}}
                                placeholder="Enter title"
                                onChange={handleChange}
                                type="text"
                                value={formState.meta_title}
                            />
                            <InputBase
                                fullWidth
                                id="meta_description"
                                name="meta_description"
                                multiline
                                rows={4}
                                placeholder="Enter description.."
                                onChange={handleChange}
                                type="text"
                                value={formState.meta_description}
                            />
                        </div>
                    </Paper>
                        <div className="description_editor__main" >
                            <h3>Опис товару</h3>
                            <CKEditor
                                editor={Editor}
                                data={formState.description || ''}
                                name="description"
                                onChange={(event, editor) => {
                                    const data = editor.getData()
                                    console.log({ event, editor, data })
                                    handleDescriptionChange(data)
                                }}
                                
                            />
                        </div>
                    </div>
                    <Button type="submit" fullWidth variant="contained" color="secondary">
                        Створити
                    </Button>
                </form>
            </div>
        </Paper>
    );
}

export default ProductCreate;