import React, { useEffect, useState } from 'react';
import styles from './Order.module.scss';
import Order from './Order';
import { useApi } from '../../hooks/useApi';
import { IOrder } from '../../types';
import { 
    Paper, TableContainer,
    Table, TableRow, TableCell,
    TableHead,
    TableBody,
 } from '@mui/material';
import OrderItem from './OrderItem';


const Orders: React.FC = () => {

    const { data, error, loading, refresh } = useApi('GET', `${process.env.REACT_APP_API}/orders`);
    const [ordersData, setOrdersData] = useState<IOrder[]>();

    useEffect(() => {
        setOrdersData(data);
    }, [data, refresh]);

    const handleStateRefresh = () => {
        refresh();
    }

    return(
        <div className={styles.order_main}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Номер замовлення</TableCell>
                            <TableCell>Клієнт</TableCell>
                            <TableCell>Номер телефону</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Місто</TableCell>
                            <TableCell>Сума</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ordersData && ordersData.reverse().map(order => (
                            <OrderItem refresh={handleStateRefresh} order={order} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default Orders;