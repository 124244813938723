import React from 'react'
import {
    Paper, Divider
} from '@mui/material';
import './InputCard.scss';
import Logo from '../../components/Logo';

const InputCard = (props) => {
    const { children, title } = props;
    return(
        <div className="login-card">
            <Paper>
                <div className="login-logo">
                    <div className="login-logo__wrapper">
                        <Logo />
                    </div>
                </div>
                    <Divider />
                    <div className="login-card-header">{title}</div>
                        <Divider />
                    <div className="login-card-content__wrapper">
                        <div className="login-card-content">
                            {children}
                        </div>
                    </div>
            </Paper>
        </div>
    );
}

export default InputCard;