import React, { useState, useEffect } from 'react';
import NavLink from '../../../components/NavLink';
import {
    List,
    ListItem,
    ListItemText, ListItemIcon
} from '@mui/material';
import {styled} from '@mui/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { setRoute, clearRoutes } from '../../../redux/actions/routing';
import { clearUserData  } from '../../../redux/actions/auth';
import { IRoutesState } from '../../../redux/reduxTypes';

const MENU = [
    {id: 1, title: 'Головна', link: '/', icon: ''},
    {id: 2, title: 'Замовлення', link: '/orders', icon: ''},
    {id: 3, title: 'Товари', link: '/products', icon: '' },
    {id: 4, title: 'Категорї', link: '/categories', icon: ''},
    {id: 5, title: 'FAQs', link: '/faqs', icon: ''},
    {id: 6, title: 'Платежі', link: '/payments', icon: ''},
    {id: 7, title: 'Відгуки', link: '/reviews', icon: ''},
    {id: 8, title: 'Статті', link: '/articles', icon: ''},
    {id: 9, title: 'Промокоди', link: '/promocodes', icon: ''}
]

const SidebarList = styled(List)({
    display: 'flex',
    paddingTop: '5px',
    flexFlow: 'column nowrap',
    height: '90%'
});

interface SidebarMenuProps {
    setRoute: (arg: string) => void;
    onClose: () => void;
    route: string;
    clearAuthData: (arg: void) => void;
    clearRoutes: (arg: void) => void;
}

interface DispatchProps {
    setRoute: (arg: string) => void;
    clearAuthData: () => void;
    clearRoutes: () => void;
}

const SidebarMenu:React.FC<SidebarMenuProps> = (props) => {

    const { setRoute, onClose, route, clearAuthData, clearRoutes } = props;

    const { logout } = useAuth0();
    
    const [menuSelected, setSelected] = useState(0);
    
    useEffect(() => {
        const selectedRoute = MENU.find(el => el.link === route);
        if (selectedRoute) {
            handleSelected(selectedRoute.id)
        }
    }, [])

    const handleSelected = (id: number) => {
        const idx = id - 1;
        setRoute(MENU[idx].link);
        setSelected(id);
        onClose();
    }

    const handleLogout = () => {
        clearAuthData();
        clearRoutes();
        logout();
    }

    return(
        <SidebarList
            >
            <div className="sidebar-main__menu">
                {MENU.map((item, index) => (
                    <NavLink key={index} to={item.link}>
                        <ListItem 
                            selected={menuSelected === item.id} 
                            onClick={() => handleSelected(item.id)}
                            color="primary"  
                            button 
                            key={item.id}>
                                <ListItemText key={item.id} primary={item.title} />
                                <ListItemIcon children={item.icon} />
                        </ListItem>
                    </NavLink>
                ))}
            </div>
            <NavLink to='/logout'>
                <ListItem
                    color="primary"
                    button
                    selected={menuSelected === 99}
                    onClick={handleLogout}
                    >   
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Вихід" />
                </ListItem>
            </NavLink>
        </SidebarList>
    );
};

const mapDispatchToProps = <T extends Function>(dispatch: T): DispatchProps => {
    return {
        setRoute: (path: string) => dispatch(setRoute(path)),
        clearAuthData: () => dispatch(clearUserData()),
        clearRoutes: () => dispatch(clearRoutes())
    }
}

const mapStateToProps = (state: IRoutesState) => {
    const route = state.routeReducer.route;
    return {
        route
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
