import React, { useEffect, useState } from 'react';
import {
    TextField, MenuItem 
} from '@mui/material';
import { useApi } from '../../hooks/useApi';
import { ICategory } from '../../types';
const CategorySelect = (props) => {

    const { selectCategory, categoryCurrent } = props;

    const [formState, setFormState] = useState({
        category: '',
        categoryData: []
    })
    const { data } = useApi('GET', `${process.env.REACT_APP_API}/categories`);
    useEffect(() => {
        categoryCurrent && (
            setFormState((prevState: any) => ({
                ...prevState,
                category: categoryCurrent
            }))
        )
    }, [categoryCurrent])
    
    useEffect(() => {
        data && (
            setFormState((prevState: any) => ({
                ...prevState,
                categoryData: data
            }))
        )
    }, [data])

    const handleSelect = (event: React.ChangeEvent<{ value: unknown}>) => {
        const value = event.target.value;
        
        setFormState((prevState: any) => ({
            ...prevState,
            category: value
        }))         
        selectCategory(value);
    }

    return(
        <TextField
            id="select"
            label="Категорія"
            select
            variant="outlined"
            margin="normal"
            onChange={handleSelect}
            value={formState.category}>
                {(typeof(formState.categoryData) !== 'undefined') && (                                
                    formState.categoryData.map((cat: ICategory) => (                               
                        <MenuItem 
                        key={cat.id}
                        value={cat.id}
                        >
                            {cat.name}
                        </MenuItem>
                    ))
                )}
        </TextField>
    );
}

export default CategorySelect;