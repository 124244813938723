import React from 'react';
import { 
    Dialog, DialogActions, DialogContentText, DialogTitle,
    Button
} from '@mui/material';
import {httpRequest} from '../../../utils/index';
import { useNavigate } from 'react-router-dom';

interface ProductDeleteDialog {
    open: boolean;
    onClose: () => void,
    productDeletedId: string
}

const ProductDeleteDialog:React.FC<ProductDeleteDialog> = (props) => {
    const navigate = useNavigate();
    const { open, onClose, productDeletedId } = props;
    const handleProductDeletion = () => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/products/${productDeletedId}`)
        .then(res => {
            navigate('/products')
        })
        .catch(err => {
            console.log(err)
        })
    }
    return(
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="delete-dialog">
            <DialogTitle>Ви впевнені що хочете видалити цей товар?</DialogTitle>
            <DialogContentText>Видалення цього продукту призведе до повного видалення товару без можливості його відновлення.</DialogContentText>
            <DialogActions>
                <Button onClick={onClose}>
                    Скасувати
                </Button>
                <Button onClick={handleProductDeletion} color="secondary">
                    Видалити
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductDeleteDialog;