import React from 'react';
import {
    AppBar,
    Toolbar, IconButton, useMediaQuery, Avatar, Typography
} from '@mui/material';
import { styled} from '@mui/styles';
import { connect } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
const AppBarStyled = styled(AppBar)({
    backgroundColor: 'white',
    color: 'black'
});

const ToolbarStyled = styled(Toolbar)({
    maxHeight: '56px'
});

interface UserProfile {
    name: string,
    email: string
}

interface HeaderProps {
    drawerOpen: (arg: boolean) => void,
    user?: UserProfile
}

const Header: React.FC<HeaderProps> = props => {
    const { drawerOpen } = props;
    const matches = useMediaQuery(`(max-width:600px)`);
    const handleHamClick = () => {
        drawerOpen(true);
    }

    return (
        <AppBarStyled position="fixed" elevation={1}>
            <ToolbarStyled>
                {matches && (
                    <IconButton aria-label="hamburger" onClick={handleHamClick} size="large">
                        <MenuIcon />
                    </IconButton>
                )}
                {props.user &&
                <>
                    <Avatar>{props.user.name.charAt(0)}</Avatar>
                    <Typography>{props.user.email}</Typography>
                </>
                }
            </ToolbarStyled>
        </AppBarStyled>
    );
};

const mapStateToProps = (state: any) => {
    const user = state.authReducer.user;
    return {
        user
    }
}

export default connect(mapStateToProps)(Header);