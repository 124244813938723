import moment, { MomentInput } from 'moment';
import 'moment/locale/uk';

type IParam = 'ymd' | 'hour' | 'LLL';

export const formatDate = (date: MomentInput, param: IParam) => {
    let formatedDate;
    moment.locale('uk')
    switch (param) {
        case 'ymd':
            return formatedDate = moment(date).format('YYYY-MM-DD'); 
        case 'hour':
            return formatedDate = moment(date).format('LT'); 
        case 'LLL': 
            return formatedDate = moment(date).format('LLL');
        default:
            return formatedDate;
    }
    
}