export const AUTH_BOOLEAN = 'AUTH_BOOLEAN'
export const USER_DATA = 'USER_DATA'    
export const SET_ROUTE = 'SET_ROUTE'
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA'
export const CLEAR_ROUTES = 'CLEAR_ROUTES'

interface SetAuthStatusAction {
    type: typeof AUTH_BOOLEAN,
    value: boolean
}

interface SetUserDataActions {
    type: typeof USER_DATA,
    user: object
}

interface ClearAuthData {
    type: typeof CLEAR_AUTH_DATA;
}

export type AuthTypes = SetAuthStatusAction | SetUserDataActions | ClearAuthData;