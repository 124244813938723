import {
    AuthTypes,
    AUTH_BOOLEAN,
    CLEAR_AUTH_DATA,
    USER_DATA,
    } from '../actions/actionTypes';
    
    const initialState = {
        isAuthenticated: false,
        user: null,
    }
    
    export default function authReducer(state = initialState, action: AuthTypes) {
        switch(action.type) {
            case AUTH_BOOLEAN: {
                return {
                    ...state,
                    isAuthenticated: action.value
                }
            }
            case USER_DATA: {
                return {
                    ...state,
                    user: action.user
                }
            }
            case CLEAR_AUTH_DATA: {
                return {
                    ...state,
                    isAuthenticated: false,
                    user: null
                }
            }
            default: 
                return state;
        }
    }