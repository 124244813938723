import React, { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './Reviews.module.scss';
import {
    Button, IconButton
} from '@mui/material';
import { httpRequest } from '../../utils';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';

const Reviews:React.FC = () => {
    const { data, error, loading, refresh } = useApi('GET', `${process.env.REACT_APP_API}/reviews`) 
    const navigate = useNavigate();

    const handleReviewAction = (id, review) => {
        httpRequest('PATCH', `${process.env.REACT_APP_API}/reviews/${id}`, {
            approved: !review.approved
        })
    }

    const handleReviewDelete = (id) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/reviews/${id}`)  
        .then(res => {
            refresh();
        })
    }

    const handleReviewIdClick = (id: number) => {
        navigate(`/review/edit/${id}`)
    }

    return(
        <div className={styles.reviews_container}>
            {data && data.map(review => (
                <div key={`${review.id}--review-card`} className={styles.review_item__card}>
                    <header className={styles.review_item__card_header}>
                        <span>id: {review.id}</span>
                        <span>
                            {review.approved ? 
                            <span style={{color: 'green'}}>Підтверджений</span> : 
                            <span style={{color: 'orange'}}>Непідтверджений</span>}
                        </span>
                        <div>
                            <Button
                                size="small"
                                onClick={(e) => handleReviewAction(review.id, review)}>
                                {review.approved ? (
                                    "Відхилити"
                                ) : "Підтвердити"}
                            </Button>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', flexGrow: 1}}>
                            <IconButton onClick={() => handleReviewDelete(review.id)}>
                                <Delete />
                            </IconButton>
                            <IconButton onClick={() => handleReviewIdClick(review.id)} color='primary' aria-label='edit'>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </header>
                        <span>Автор: {review.user?.name ?? review.review_user_name}</span>
                        <span>Коментар до: {review.product.name}</span>
                        <div className={styles.review_item__comment}>
                            <span>Коментар</span>
                            <div>
                                {review.commentText}
                            </div>
                        </div>
                        <div>
                            {review.youtube_link}
                        </div>
                        <div className={styles.comment_img__content}>
                            {review.review_images.length > 0 && (
                                review.review_images.map(img => (
                                    <div key={`${img.id}-image`}>
                                        <img className={styles.comment_img} src={img.url} alt={img.name} />
                                    </div>
                                ))
                            )}
                        </div>
                </div>
            ))}
        </div>
    );
};

export default Reviews;