import { IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import RefreshIcon from '@mui/icons-material/Refresh';
import { httpRequest } from '../../utils/index';

const TABLE_ROWS = [
    {
        id: 1,
        name: 'id'
    },
    {
        id: 2,
        name: 'Статус'
    },
    {
        id: 3,
        name: 'session_id'
    },
    {
        id: 4,
        name: 'Сума'
    },
    {
        id: 5
    }
]

const Payments = () => {

    const { data, error, loading, refresh } = useApi('GET', `${process.env.REACT_APP_API}/novapay/payments`);
    
    useEffect(() => {
        if (!loading) {
            console.log(data)
        }
    }, [loading]);

    const handleStatusRefresh = async (sessId) => {
        const response = await httpRequest('POST', `${process.env.REACT_APP_API}/novapay/get-status`, {
            session_id: sessId
        });

        console.log(response);
    }

    return(
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {TABLE_ROWS.map(row => (
                                <TableCell key={row.id}>
                                    {row.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                        {loading ? (
                            <TableRow>
                                <TableCell sx={{padding: 0}} colSpan={3}>
                                    <LinearProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableBody>
                                {!loading && data.length > 0 && (
                                    data.reverse().map(cell => (
                                        <TableRow key={cell.id}>
                                            <TableCell>{cell.id}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{cell.session_id}</TableCell>
                                            <TableCell>{cell.amount}</TableCell>
                                            <TableCell align='right'>
                                                <IconButton color="primary" onClick={() => handleStatusRefresh(cell.session_id)}>
                                                    <RefreshIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        )}  
                </Table>
            </TableContainer>
        </div>
    );
};

export default Payments;