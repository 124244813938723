class StateSaver {
    loadState() {
        let serializedState = localStorage.getItem('userData');
        if (serializedState) {
            return JSON.parse(serializedState);
        }
    }

    saveState<T>(state: T) {
        let serializedState = JSON.stringify(state);
        if (serializedState) {
            localStorage.setItem('userData', serializedState);
        }
    }
}

export { StateSaver };