import { useEffect, useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios, { Method, ResponseType } from 'axios';
import { AuthService } from '../services/AuthService';

export interface IOptions {
    audience: string,
    scope: string
}

export interface IFetchOpts extends Array<string> {
    headers?: string[],
    data?: string[]
}

interface IState {
    error?: Error | null | undefined,
    loading?: boolean,
    data?: any
}
 
export const useApi = <A extends any >(method: Method, path: string, condition:boolean=true, ...fetchOptions: IFetchOpts) => {
    const { getAccessTokenSilently } = useAuth0();
    const [state, setState] = useState<IState>({
        error: null,
        loading: true,
        data: undefined
    });
    const options: IOptions = {
        audience: `${process.env.REACT_APP_AUDIENCE}`,
        scope: 'read:users'
    }
    const [refreshIndex, setRefreshIndex] = useState<number>(0);
    const authService = new AuthService();
    useEffect(() => {
        if (condition) {
        (async () => {
            try {
                const { audience, scope} = options;
                const accessToken = await getAccessTokenSilently({ audience, scope});
                authService.setLocalStorageItem('accessToken', accessToken);
                const res: Promise<ResponseType> = await axios({
                    method: method,
                    url: path,
                    ...fetchOptions,
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    console.log(err);
                })
                setState({
                    ...state,
                    data: await res,
                    error: null,
                    loading: false
                })
            } catch (error: any) {
                setState({
                    ...state,
                    error,
                    loading: false
                });
            }
        })();
        }
    }, [refreshIndex, condition])

    return {
        ...state,
        refresh: () => setRefreshIndex(refreshIndex + 1)
    }
}
