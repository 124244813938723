import React, { useState } from 'react';
import styles from './Articles.module.scss';
import {
    Button, Dialog, DialogActions, DialogContent, TextField,
    DialogTitle, InputBase, Typography, IconButton
} from '@mui/material';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CKEditor} from '@ckeditor/ckeditor5-react';
import { httpRequest } from '../../utils';
import { useApi } from '../../hooks/useApi';
import CategorySelect from '../Categories/CategorySelect';

const Articles:React.FC = () => {
    const [open, setOpen] = useState(false);
    const {loading, data, refresh} = useApi('GET', `${process.env.REACT_APP_API}/article`);
    const [editId, setEditId] = useState(null);
    const [formState, setFormState] = useState({
        article_title: '',
        article_description: '',
        article_content: '',
        article_image: '',
        article_slug: '',
        category: ''
    });
    const handleCreate = () => {
        setOpen(true);
        setEditId(null);
        setFormState({
            article_title: '',
            article_content: '',
            article_description: '',
            article_image: '',
            article_slug: '',
            category: ''
        })
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleCategoryChange = (catId) => {
        setFormState((prevState) => ({
            ...prevState,
            category: catId 
        }))
    }

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        httpRequest(editId ? 'PATCH' : 'POST',editId ? `${process.env.REACT_APP_API}/article/${editId}` : `${process.env.REACT_APP_API}/article`,  {
            ...formState
        })
        .then(() => {
            refresh();
            handleClose();
        })
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const name = event.target.name;

        setFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    }
    
    const handleDescriptionChange = (data) => {
        setFormState((prevState: any) => ({
            ...prevState,
            article_content: data
        }));
    }

    const handleArticleDelete = (id) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/article/${id}`)
        .then(() => {
            refresh();
        })
    }

    const handleArticleEdit = (article) => {
        setFormState({
            article_title: article.article_title ?? " ",
            article_description: article.article_description ?? " ",
            article_content: article.article_content ?? " ",
            article_image: article.article_image ?? ' ',
            category: article.category.id ?? ' ',
            article_slug: article.article_slug ?? ' ',
        })
        setOpen(true);
        setEditId(article.id);
    }

    return(
        <div className={styles.page_container}>
            <div className={styles.page_actions__header}>
                <Button onClick={handleCreate}>
                    Створити
                </Button> 
                <div className={styles.articles__content}>
                    {data && data.map((article) => (
                        <div key={`${article.id}--article-key${article.article_title}`} className={styles.article_card__main}>
                            <div className={styles.article_header__actions}>
                                <p>{article.article_title}</p>
                                <div>
                                    <IconButton  >
                                        <EditIcon onClick={() => handleArticleEdit(article)}/>
                                    </IconButton>
                                    <IconButton>
                                        <DeleteIcon onClick={() => handleArticleDelete(article.id)} />
                                    </IconButton>
                                </div>
                            </div>
                            <div>
                                {article.article_description}
                            </div>
                            <div>
                                {article.article_content}
                            </div>
                        </div>
                    ))}
                </div>
                <Dialog
                    open={open}
                    disableEnforceFocus
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle>Створити Статтю</DialogTitle>
                    <form onSubmit={handleFormSubmit} >
                    <DialogContent>
                            <TextField
                                autoFocus
                                variant="outlined"
                                required={true}
                                margin="dense"
                                id="name"
                                label="Назва статті"
                                type="text"
                                name="article_title"
                                value={formState.article_title}
                                fullWidth
                                onChange={handleChange}
                            />
                            <TextField
                                autoFocus
                                variant="outlined"
                                required={true}
                                margin="dense"
                                id="name"
                                label="Посилання на статтю"
                                type="text"
                                name="article_slug"
                                value={formState.article_slug}
                                fullWidth
                                onChange={handleChange}
                            />
                            <Typography>SEO</Typography>
                            <InputBase
                                fullWidth
                                id="article_description"
                                name="article_description"
                                style={{color: 'blue'}}
                                placeholder="Enter description"
                                onChange={handleChange}
                                type="text"
                                value={formState.article_description}
                            />
                            <TextField
                                autoFocus
                                variant="outlined"
                                required={true}
                                margin="dense"
                                id="name"
                                label="Article Image"
                                type="text"
                                name="article_image"
                                value={formState.article_image}
                                fullWidth
                                onChange={handleChange}
                            />
                            <CategorySelect
                                selectCategory={handleCategoryChange}
                                categoryCurrent={formState.category}
                            /> 
                            <div>
                                <h4>Опис статті</h4>
                                <CKEditor
                                    editor={Editor}
                                    data={formState.article_content || ''}
                                    name="article_content"
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        console.log({ event, editor, data })
                                        handleDescriptionChange(data)
                                    }}>
                                </CKEditor>
                            </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={handleClose}>
                            Cкасувати
                        </Button>
                        <Button type="submit" >
                            {editId ? 'Оновити' : 'Створити'}
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
}

export default Articles;