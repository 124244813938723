import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingScreen: React.FC = () => {
    return(
        <span style={{
            height: "100%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <CircularProgress size={100} color="primary" />
        </span>
    );
}

export default LoadingScreen;