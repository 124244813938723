import React, { useEffect, useState } from 'react';
import {
Button, TextField, Paper, MenuItem,
List, ListItem, IconButton
} from '@mui/material';
import { ICategory } from '../../types';
import { httpRequest } from '../../utils';
import { CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from '../../hooks/useApi';

const FAQform = (props) => {
    const { show, refresh, questions } = props;
    const [formState, setFormState] = useState({
        question_name: "",
        category: '',
        question_text: ''
    })
    const [categoriesData, setCategoriesData] = useState([]);

    useEffect(() => {
        httpRequest('GET', `${process.env.REACT_APP_API}/categories`)
                .then(res => {
                    setCategoriesData(res);
                })
                .catch(err => {
                    console.log(err);
                })
    }, []) 

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormState((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSelect = (event: React.ChangeEvent<{ value: unknown}>) => {
        const value = event.target.value;

        setFormState((prevState: any) => ({
            ...prevState,
            category: value
        }))
    }

    const handleDescriptionChange = (data) => {
        setFormState((prevState: any) => ({
            ...prevState,
            question_text: data
        }))
    }

    const handleSubmit = () => {
        httpRequest('POST', `${process.env.REACT_APP_API}/category-question/create`, formState)
        .then((res) => {
            refresh()
        })
    }

    const handleDelete = (id) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/category-question/${id}`)
            .then(() => {
                refresh();
            })
    }

    return <>
    {questions && (
            <List>
                {questions.map(q => (
                    <ListItem key={q.id}>{q.question_name} | {q.category.name} 
                        <IconButton onClick={() => handleDelete(q.id)} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        )}
    {show && (
    <form style={{display: 'flex', flexFlow: 'column nowrap', maxWidth: '300px'}}>
        <TextField
            id="faq-question-name"
            name="question_name"
            value={formState.question_name}
            variant="outlined"
            type="text"
            margin="normal"
            label="Назва запитання"
            onChange={handleChange}
        />
        <TextField
            id="select"
            label="Категорія"
            select
            variant="outlined"
            margin="normal"
            onChange={handleSelect}
            value={formState.category}>
                {(typeof(categoriesData) !== 'undefined') && (                                
                    categoriesData.map((cat: ICategory) => (                               
                        <MenuItem 
                        key={cat.id}
                        value={cat.id}
                        >
                            {cat.name}
                        </MenuItem>
                    ))
                )}
        </TextField>
        <CKEditor
            editor={Editor}
            name="question_text"
            data={formState.question_text || ''}
            onChange={(event, editor) => {
                const data = editor.getData()
                handleDescriptionChange(data)
            }}
        />
        <Button 
            variant="contained"
            onClick={handleSubmit}>
            Створити
        </Button>
    </form>)}
    </>;
}

const FAQs = () => {
    const [creationStart, setCreationStart] = useState(false);
    const [questions, setQuestions] = useState([]);
    const { data, refresh } = useApi('GET', `${process.env.REACT_APP_API}/category-question`);

    useEffect(() => {
        if (data) {
            setQuestions(data)
        }
    }, [data])


    const handleFormOpen = () => {
        setCreationStart(true)
    }
    return(
        <Paper >
            <div style={{padding: '5px'}}>
                <Button
                    variant="outlined"
                    onClick={handleFormOpen}>
                        Додати новий FAQ
                </Button>
                <FAQform
                    questions={questions}
                    show={creationStart}
                    refresh={refresh}
                />
            </div>
        </Paper>
    );
}

export default FAQs;