import { Chip } from '@mui/material';
import React from 'react';
import { httpRequest } from '../../../utils';
import '../Products.scss';

interface IProductTags {
    tagsArr: ITag[]
}

interface ITag {
    tag_name: string;
    tag_slug: string;
    id: string;
}

const ProductTags:React.FC<IProductTags> = (props) => {
    const { tagsArr } = props;
    const handleTagDelete = (id: string) => {
        httpRequest('DELETE', `${process.env.REACT_APP_API}/product-tag/${id}`)
            .then(res => {

            })
            .catch(err => {
                console.log(err);
            })
    }
    return(
        <div className="product-tags-main">
            {tagsArr.map(tag => (
                <Chip 
                    label={tag.tag_name}
                    onDelete={() => handleTagDelete(tag.id)} 
                    color="secondary"
                />
            ))}  
        </div>
    );
};

export default ProductTags;