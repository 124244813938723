import React from 'react';
import { 
    Button
} from '@mui/material';
import NavLink from '../../components/NavLink';
import AddIcon from '@mui/icons-material/Add';
const ProductActions = () => {
    return(
        <div className="products-page_actions">
            <NavLink to="/products/create">
                <Button variant="outlined" endIcon={<AddIcon />}>
                    Додати Товар
                </Button>
                {/* Add refresh button */}
            </NavLink>
        </div>
    );
};

export default ProductActions;