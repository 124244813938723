import React, { FC, useState } from 'react';
import {
    FormControl, InputLabel, MenuItem, Select
} from '@mui/material';

interface OrderSelectProps {
    label: string;
    defaultStatus?: 'pending' | 'processing' | 'completed';
}

const OrderSelect: FC<OrderSelectProps> = ({ label }) => {
    
    const [selectVal, setSelectVal] = useState('');
    
    const handleChange = (event) => {
        setSelectVal(event.target.value);
    }

    return(
        <FormControl style={{minWidth: '120px', margin: '20px'}}>
            <InputLabel id="order-select-id">{label}</InputLabel>
            <Select
                labelId="order-select-id"
                id="order-select-id"
                value={selectVal}
                onChange={handleChange}>
                <MenuItem value="pending">Нове замовлення</MenuItem>
                <MenuItem value="processing">Обробляється</MenuItem>
                <MenuItem value="completed">Виконано</MenuItem>
                <MenuItem value="cancelled">Скасовано</MenuItem>
                <MenuItem value="on-hold">Утримується</MenuItem>
            </Select>
        </FormControl>
    );
}

export default OrderSelect;