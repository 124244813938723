import { AUTH_BOOLEAN, USER_DATA, AuthTypes, CLEAR_AUTH_DATA } from './actionTypes';

export function authStatus(status: boolean): AuthTypes {
    return {
        type: AUTH_BOOLEAN,
        value: status
    }
}

export function setUserData(data: AuthTypes): AuthTypes {
    return {
        type: USER_DATA,
        user: data
    }
}

export function clearUserData() {
    return {
        type: CLEAR_AUTH_DATA
    }
}
