import React, { useState } from 'react';
import { Paper, Table, TableBody, TableContainer, TableCell,
    IconButton, Tooltip, Menu, MenuItem
,TableHead, TableRow, TableSortLabel } from '@mui/material';
import { IProduct } from '../../../types/index';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BuildIcon from '@mui/icons-material/Build';
import { NavigateBeforeOutlined } from '@mui/icons-material';

const TABLE_HEAD = [
    {id: 0, name: 'Id', key: 'id'},
    {id: 1, name: '', key: ''},
    {id: 2, name: 'Назва', key: 'name'},
    {id: 3, name: 'Ціна', key: 'price'},
    {id: 4, name: 'SKU', key: 'sku'},
    {id: 5, name: 'Акційна ціна', key: 'sale_price'},
    {id: 6, name: 'Статус', key: 'status'},
    // {id: 7, name: 'Продано', key: 'sold'},
    {id: 8, name: 'Категорія', key: 'category'},
    {id: 9, name: 'К-ть', key: 'qty'},
    {id: 10, name: '', key: ''},

]

interface ProductTableProps {
    data?: IProduct[];
    loading?: boolean;
    handleCopy: (id: number) => void;
}

const ProductsTableMenu = (props) => {
    const { id, handleCopy } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleCreateCopy = (id: number) => {
        handleCopy(id);
        handleClose();
    }

    const handleEditClick = (id: number) => {
        navigate(`/products/edit/${id}`)
    }

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }
    return <>
    <IconButton onClick={handleOpen} size="large">
        <BuildIcon />
    </IconButton>
    <Menu
        id="product-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem value="edit">
            <Tooltip title="Редагувати товар">
                <IconButton onClick={() => handleEditClick(id)} size="large">
                    <EditIcon />
                </IconButton>
            </Tooltip>
        </MenuItem>
        <MenuItem value="copy">
            <Tooltip title="Копіювати товар">
                <IconButton onClick={() => handleCreateCopy(id)} size="large">
                    <FileCopyIcon />
                </IconButton>
            </Tooltip>
        </MenuItem>
    </Menu>
    </>;
}

type Order = 'asc' | 'desc';

const ProductsTable:React.FC<ProductTableProps> = (props) => {
    
    const { data, handleCopy } = props;
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('id');

    const createSortHandler = (key) => {
        const isAsc = orderBy === key && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(key);
    }

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
      ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

    function stableSort<T>(array: any, comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
        return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    return(
        <TableContainer component={Paper}>
            <Table  size="small">
                <TableHead>
                    <TableRow>
                        {TABLE_HEAD.map((it, index) => (
                                <TableCell key={`${it.name}-${index}`}>
                                    <TableSortLabel
                                        active={orderBy === it.key}
                                        direction={orderBy === it.key ? order : 'asc'}
                                        key={`${it.id}-orderLabel`}
                                        onClick={() => createSortHandler(it.key)}>
                                            {it.name}
                                    </TableSortLabel>
                                    
                                </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.length > 0 && stableSort(data, getComparator(order, orderBy)).map((product) => (product.type === 'simple' || product.type === 'variable') && (
                        <TableRow key={product.id} hover>
                                <TableCell>{product.id}</TableCell>
                            <TableCell>
                                {product.type === 'simple' && (
                                    <img alt="Зображення товару" className="product-table_img" src={`${process.env.REACT_APP_DEPLOYED_URL}/images/${product.imgUrl}`} />
                                )}
                                {(product.type === 'variable' && product.children) && (
                                    product.children[0] && (
                                        <img alt="Зображення товару" className="product-table_img" src={`${process.env.REACT_APP_DEPLOYED_URL}/images/${product.children[0].imgUrl}`} />
                                    ) 
                                        
                                )}
                            </TableCell>
                            <TableCell>{product.name}</TableCell>
                            <TableCell>{product.price}</TableCell>
                            <TableCell>{product.sku}</TableCell>
                            <TableCell>{product.sale_price}</TableCell>
                            <TableCell>{product.status}</TableCell>
                            {/* <TableCell>{product.total_sales}</TableCell> */}
                            <TableCell>{product.category.name}</TableCell>
                            <TableCell>{product.qty}</TableCell>
                            <TableCell>
                                <ProductsTableMenu 
                                    id={product.id} 
                                    handleCopy={(id: number) => handleCopy(id)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProductsTable;